.wax-button {
  border: 1px solid #F7D64E;
  border-radius: 2px;
  padding: 2px;
  background: transparent;
  cursor: pointer;

  svg {
    padding-right: 8px;
  }

  &__content {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 12px 18px;
    background: #F5C913;
    box-sizing: border-box;

    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #1F232D;
  }
}