.join {
  width: calc(100% - (116*2) * 1px);
  box-sizing: border-box;
  margin: 260px 116px 116px 116px;
  padding: 75px 48px;
  display: flex;
  border: 2px solid #272727;
  background: radial-gradient(31.38% 92.25% at 88.13% 49.91%, #D6D6D6 0%, rgba(23, 23, 23, 0) 100%);

  &__title {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
  }

  &__content {
    flex: 1;
  }

  &__button {
    margin: 38px auto 0 auto;
    width: 286px;
  }
}