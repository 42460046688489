.main {
  width: 100%;
  padding-top: 50px;
  text-align: center;
  max-width: 760px;
  margin: 0 auto;

  &__title {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
  }

  &__description {
    padding-top: 16px;
    font-size: 24px;
    line-height: 28px;
  }

  &__invited-by {
    padding-top: 28px;
    font-size: 20px;
    line-height: 28px;
    color: #ACACAC;

    span {
      text-decoration: underline;
    }
  }

  &__button {
    margin: 34px auto 0 auto;
  }

  &__what-is {
    padding-top: 26px;
    color: #F5DF4D;

    font-family: 'Orbitron', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    text-decoration: underline;
  }
}