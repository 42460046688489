.deepmine {
    padding-top: 35vw;

  &__title {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
  }

  &__description {
    margin: 40px auto 0 auto;
    position: relative;
    max-width: 600px;
    height: 390px;

    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #ACACAC;

    &__bg {
      position: absolute;
      z-index: -1;
      top: 11px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}