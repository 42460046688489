.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 80px;

  a {
    color: white;
    text-decoration: none;

    svg {
      margin: 0 12px;
    }

    &:first-child {
      svg {
        margin-left: 0;
      }
    }
  }

  &__icon-content {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  &__section {
    min-width: 180px;
    text-align: center;
  }
}